<template>
    <div>
        <navbar />
        <main>
            <div class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                <div class="absolute top-0 w-full h-full bg-center bg-cover imgHeader">
                    <span id="blackOverlay" class="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div class="container relative mx-auto">
                    <div class="items-center flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                            <div class="">
                                <h1 class="text-white font-semibold text-5xl">
                                    Consulenza IT
                                    <br />
                                    Sviluppo Software
                                </h1>
                                <p class="mt-4 text-lg text-blueGray-200">
                                    Sono un professionista che ha fatto della sua passione un lavoro. Mi occupo di programmazione e assistenza tecnica da
                                    diversi anni, cerco sempre di dare un ottimo servizio, un prodotto funzionale e semplice.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" style="transform: translateZ(0)">
                    <span id="services" style="position: absolute; top: -200px"></span>
                    <svg
                        class="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>
            </div>

            <section class="pb-20 bg-blueGray-200 -mt-24">
                <div class="container mx-auto px-4">
                    <div class="flex flex-wrap">
                        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div class="px-4 py-5 flex-auto">
                                    <div
                                        class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                                    >
                                        <i class="fas fa-keyboard"></i>
                                    </div>
                                    <h6 class="text-xl font-semibold">Sviluppo WEB</h6>
                                    <p class="mt-2 mb-4 text-blueGray-500">
                                        Lascia la tua impronta sul web, un sito web è un'ottima vetrina e un punto di riferimento per trovare e comunicare con i
                                        clienti.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="w-full md:w-4/12 px-4 text-center">
                            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div class="px-4 py-5 flex-auto">
                                    <div
                                        class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                                    >
                                        <i class="fas fa-warehouse"></i>
                                    </div>
                                    <h6 class="text-xl font-semibold">Sviluppo Applicazioni Custom</h6>
                                    <p class="mt-2 mb-4 text-blueGray-500">
                                        Sviluppo di applicazioni Custom sia web che non.<br />
                                        Stai cercando un prodotto che si adatti al meglio alle tue esigenze possiamo farlo!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
                            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div class="px-4 py-5 flex-auto">
                                    <div
                                        class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                                    >
                                        <i class="fas fa-server"></i>
                                    </div>
                                    <h6 class="text-xl font-semibold">Hosting e Posta</h6>
                                    <p class="mt-2 mb-4 text-blueGray-500">
                                        Gestione totale, dalla creazione alla gestione di uno spazio web per le tue esigenze e Gestione completa della posta,
                                        con servizi gestibili e personalizzabili.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mx-auto px-4 mt-20">
                        <div class="items-center flex flex-wrap">
                            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img alt="..." class="max-w-full rounded-lg shadow-lg" src="@/assets/img/collabora.jpg" />
                            </div>
                            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div class="md:pr-12">
                                    <div
                                        class="text-emerald-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-300"
                                    >
                                        <i class="fas fa-user-friends text-xl"></i>
                                    </div>
                                    <h3 class="text-3xl font-semibold">La collaborazione per <br />il miglior risultato</h3>
                                    <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                                        Collaboro a stretto contatto con diverse realtà che permettono di offrire soluzioni non solo di sviluppo web ma a tutto
                                        tondo per sopperire nel miglior modo le esigenze del cliente.
                                    </p>
                                    <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                                        <a class="" href="#contact">
                                            <button
                                                class="bg-emerald-500 active:bg-emerald-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                Contattami
                                            </button>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="relative py-20">
                <span id="about" style="position: absolute; top: -100px"></span>
                <div class="container mx-auto px-4">
                    <div
                        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style="transform: translateZ(0)"
                    >
                        <svg
                            class="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>

                    <div class="flex flex-wrap items-center">
                        <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
                            <div
                                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                            >
                                <i class="fas fa-rocket text-xl"></i>
                            </div>
                            <h3 class="text-3xl mb-2 font-semibold leading-normal">Consulente Informatico</h3>
                            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                                WOSP sta per Web & Open Source Programming, un simbolo di ciò che faccio e cioè che mi piace fare.
                            </p>
                            <p class="text-lg leading-relaxed mt-0 mb-4 text-blueGray-500">
                                Prediligo l'uso di tecnologie Libere per poter avere un controllo maggiore sul prodotto sviluppato e per l'idea di community che
                                ne consegue.
                            </p>
                        </div>

                        <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
                            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500">
                                <img alt="..." src="@/assets/img/io.png" class="w-full align-middle rounded-t-lg" />
                                <blockquote class="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        class="absolute left-0 w-full block h-95-px -top-94-px"
                                    >
                                        <polygon points="-30,95 583,95 583,65" class="text-emerald-500 fill-current"></polygon>
                                    </svg>
                                    <h4 class="text-xl font-bold text-white">Federico Pietta</h4>
                                    <p class="text-md mt-2 text-white">
                                        Sono un professionista che ha fatto della sua passione un lavoro. Mi occupo di programmazione e assistenza tecnica da
                                        diversi anni, cerco sempre di dare un ottimo servizio, un prodotto funzionale e semplice..
                                    </p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pb-20 relative block bg-blueGray-800">
                <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20" style="transform: translateZ(0)">
                    <svg
                        class="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon class="text-blueGray-800 fill-current" points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>

                <div class="container mx-auto px-4 lg:pt-24 lg:pb-64" id="contact">
                    <div class="flex flex-wrap text-center justify-center">
                        <div class="w-full lg:w-6/12 px-4">
                            <h2 class="text-4xl font-semibold text-white">Interessato a costruire qualcosa</h2>
                            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">Contattami per qualsiasi informazione.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
                <div class="container mx-auto px-4">
                    <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                                <div class="flex-auto p-5 lg:p-10">
                                    <h4 class="text-2xl font-semibold">Contatti</h4>

                                    <div class="relative w-full mb-3 mt-8">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="full-name"> Nome </label>
                                        <input
                                            v-model="form.nome"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            :class="{ 'border-red': !form.nome && errorForm }"
                                            placeholder="Nome"
                                        />
                                    </div>

                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email"> Email </label>
                                        <input
                                            v-model="form.email"
                                            type="email"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            :class="{ 'border-red': !reg.test(form.email) && errorForm }"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="message"> Messaggio </label>
                                        <textarea
                                            v-model="form.messaggio"
                                            rows="4"
                                            cols="80"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                            :class="{ 'border-red': !form.messaggio && errorForm }"
                                            placeholder="Messaggio..."
                                        />
                                    </div>
                                    <div class="text-center mt-6">
                                        <button
                                            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            @click="sendMail"
                                        >
                                            Invia
                                        </button>
                                    </div>
                                    <div
                                        v-if="alertmsg.open"
                                        class="text-white px-6 py-4 border-0 rounded relative mb-4"
                                        :class="{ 'bg-red-500': alertmsg.error, 'bg-emerald-500': !alertmsg.error }"
                                    >
                                        <span class="text-xl inline-block mr-5 align-middle"> </span>
                                        <span class="inline-block align-middle mr-8">
                                            <b class="capitalize">{{ alertmsg.titolo }}</b> {{ alertmsg.messaggio }}
                                        </span>
                                        <button
                                            class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-1 outline-none focus:outline-none"
                                            v-on:click="alertmsg.open = false"
                                        >
                                            <span>×</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer-component />
    </div>
</template>
<script>
import Navbar from '@/components/Navbars/IndexNavbar.vue'
import FooterComponent from '@/components/Footers/Footer.vue'

export default {
    data() {
        return {
            form: { nome: null, email: null, messaggio: null },
            errorForm: false,
            reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            alertmsg: { open: false, titolo: null, messaggio: null, error: false }
        }
    },
    components: {
        Navbar,
        FooterComponent
    },
    methods: {
        sendMail() {
            if (!this.form.nome || !this.reg.test(this.form.email) || !this.form.messaggio) {
                this.errorForm = true

                return
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' },
                body: `nome=${this.form.nome}&email=${this.form.email}&messaggio=${this.form.messaggio}`
            }
            fetch('lib/mail.php', requestOptions)
                .then((res) => res.text())
                .then((res) => {
                    //console.log(res)
                    if (res == 'Message has been sent') {
                        this.alertmsg.titolo = 'Inviato'
                        this.alertmsg.messaggio = 'Messaggio inviato!'
                        this.alertmsg.error = false
                        this.alertmsg.open = true
                    } else {
                        this.alertmsg.titolo = 'Errore'
                        this.alertmsg.messaggio = 'Messaggio non inviato! riprovare più tardi.'
                        this.alertmsg.error = true
                        this.alertmsg.open = true
                    }
                })
        }
    }
}
</script>
<style lang="scss">
.border-red {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) #eb2525;
    -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.imgHeader {
    background-image: url('~@/assets/img/header.jpg');
}
</style>
