<template>
    <nav class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <router-link to="/">
                    <img alt="..." :src="logo" class="mx-auto max-w-120-px" />
                </router-link>
                <button
                    class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                    type="button"
                    v-on:click="setNavbarOpen"
                >
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']" id="example-navbar-warning">
                <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li class="flex items-center">
                        <a class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold" href="#services">
                            <p>Servizi</p>
                        </a>
                    </li>
                    <li class="flex items-center">
                        <a class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold" href="#about">
                            <p>About</p>
                        </a>
                    </li>
                    <li class="flex items-center">
                        <a class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold" href="#contact">
                            <p>Contatti</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
//import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";
import logo from '@/assets/img/logo.png'

export default {
    data() {
        return {
            navbarOpen: false,
            logo
        }
    },
    methods: {
        setNavbarOpen: function () {
            this.navbarOpen = !this.navbarOpen
        }
    },
    components: {}
}
</script>
