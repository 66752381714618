import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'

// styles

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/styles/tailwind.css'

// mouting point for the whole app

import App from '@/App.vue'

// layouts

//import Admin from '@/layouts/Admin.vue'
//import Auth from '@/layouts/Auth.vue'

// views for Admin layout

//import Dashboard from '@/views/admin/Dashboard.vue'
//import Settings from '@/views/admin/Settings.vue'
//import Tables from '@/views/admin/Tables.vue'
//import Maps from '@/views/admin/Maps.vue'

// views for Auth layout

//import Login from '@/views/auth/Login.vue'
//import Register from '@/views/auth/Register.vue'

// views without layouts

//import Landing from '@/views/Landing.vue'
//import Profile from '@/views/Profile.vue'
import Index from '@/views/Index.vue'

// routes

const routes = [
    {
        path: '/',
        component: Index
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App).use(router).mount('#app')
